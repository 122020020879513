import { createContext, useEffect, useReducer, useState } from "react";
import { projectAuth } from "../Firebase/Config";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsub = projectAuth.onAuthStateChanged((user) => {
      console.log("User in onAuthStateChanged:", user);
      console.log("Firebase Auth State:", user ? "Authenticated" : "Not Authenticated");
  
      if (user) {
        // User is authenticated
        dispatch({ type: "LOGIN", payload: user });
      } else {
        // User is not authenticated
        dispatch({ type: "LOGOUT" });
      }
  
      dispatch({ type: "AUTH_IS_READY", payload: user });
      setLoading(false); // Set loading to false when auth state is ready
      unsub();
    });
  }, []);
  

  console.log("AuthContext state:", state);

  // Render children only when authIsReady is true
  return loading ? null : (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};



import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
/* eslint-disable import/prefer-default-export */
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error("useAuthContext must be inside an AuthContextProvider");
  }
  return context;
};



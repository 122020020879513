import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import { useAuthContext } from "../hooks/useAuthContext";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
// const Dashboard = Loadable(lazy(() => import("../views/dashboard/Dashboard")));
const Properties = Loadable(lazy(() => import("../views/Properties/Properties")));
const Tenants = Loadable(lazy(() => import("../views/Tenants/TenantPage")));
const Leases = Loadable(lazy(() => import("../views/Leases/LeasesPage")));
const Invoices = Loadable(lazy(() => import("../views/Transactions/Invoices/Invoice")));
const Expenses = Loadable(lazy(() => import("../views/Transactions/Expenses/Expenses")));
const Owners = Loadable(lazy(() => import("../views/Owners/Owners")));
const IndividualProperty=Loadable(lazy(() => import("../views/Properties/IndividualProperty")));
const Staff = Loadable(lazy(() => import("../views/Staff/Staff")));
const ServiceProviders = Loadable(lazy(() => import("../views/ServiceProviders/ServiceProviders")));
const Maintenance = Loadable(
  lazy(() => import("../views/Maintenance/Maintenance"))
);
// const Invoices = Loadable(lazy(() => import("../views/Invoices/Invoices")));
const Profile = Loadable(lazy(() => import("../views/User/User")));
const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Register = Loadable(
  lazy(() => import("../views/authentication/Register"))
);
const Login = Loadable(lazy(() => import("../views/authentication/Login")));

// PRIVATE ROUTE AUTHENTICATION
const PrivateRoute = ({ element }) => {
  const { authIsReady, user } = useAuthContext();

  // Log values for debugging
  console.log("authIsReady:", authIsReady);

  return authIsReady ? user ? element : <Navigate to="/auth/login" /> : null;
};

const Router = [
  {
    path: "/",
    element: <PrivateRoute element={<FullLayout />} />,
    children: [
      { path: "/", element: <Navigate to="/properties" /> },
      // { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/maintenance", exact: true, element: <Maintenance /> },
      { path: "/invoices", exact: true, element: <Invoices /> },
      { path: "/expenses", exact: true, element: <Expenses /> },
      { path: "/tenants", exact: true, element: <Tenants /> },
      { path: "/owners", exact: true, element: <Owners /> },
      { path: "/properties", exact: true, element: <Properties /> },
      { path: "/property/:propertyId", exact: true, element: <IndividualProperty /> },
      { path: "/leases", exact: true, element: <Leases /> },
      { path: "/staff", exact: true, element: <Staff /> },
      { path: "/service_providers", exact: true, element: <ServiceProviders /> },
      { path: "/statements", exact: true, element: <Maintenance /> },

      { path: "/profile", exact: true, element: <Profile /> },
      { path: "*", element: <Navigate to="/dashboard" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      {
        path: "/auth/register",
        element: <Register />,
        // Add console.log here
        action: () => console.log("Navigating to /auth/register"),
      },
      {
        path: "/auth/login",
        element: <Login />,
        // Add console.log here
        action: () => console.log("Navigating to /auth/login"),
      },
      {
        path: "*",
        element: <Navigate to="/auth/404" />,
        // Add console.log here
        action: () => console.log("Navigating to /auth/404"),
      },
    ],
  },
];

export default Router;

import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes, Navigate } from 'react-router-dom';
import Router from './routes/Router';
import { useAuthContext } from './hooks/useAuthContext';

import { baselightTheme } from './theme/DefaultColors';
import ErrorBoundary from './ErrorBoundary';

function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;
  const { authIsReady} = useAuthContext();

  return (
    <div>
      {authIsReady && (
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <CssBaseline />

              {routing}
          
          </ThemeProvider>
        </ErrorBoundary>
      )}
    </div>
  );
}

export default App;

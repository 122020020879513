import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
// import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCkUSN7aNunP-BNiEfwXKCCTzYja7q5NpQ",
  authDomain: "koditrackcloud.firebaseapp.com",
  projectId: "koditrackcloud",
  storageBucket: "koditrackcloud.appspot.com",
  messagingSenderId: "305568674052",
  appId: "1:305568674052:web:04524e14d4dab08bbb6051",
  measurementId: "G-EZKGRN5L4Q"
};
  
//   initialize firebase: this connects the app to the backend
firebase.initializeApp(firebaseConfig);

//   initialize the services we need from firebase:
const projectDatabase = firebase.firestore();
const projectAuth = firebase.auth();
const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Initialize Analytics
// const analytics = getAnalytics(firebase);

// Creating Timestamp 
const { Timestamp } = firebase.firestore;

export {
  projectDatabase,
  projectAuth,
  GoogleAuthProvider,
  Timestamp as timestamp,
};
